<template>
  <v-dialog
    v-model="modalOpen"
    scrollable
    content-class="drawing-board-dialog"
  >
    <v-card class="drawing-board-container">
      <div class="drawing-board-outer-container">
      <table class="drawing-board-ribbon-container" :style="ribbonStyle1">
        <tr>
          <td class="drawing-board-ribbon">
            <div v-if="settings.restoreEnabled">
              <label v-if="!!settings.language.restoreEnabled">
                {{ settings.language.restoreEnabled }}
              </label>
              <v-btn
                data-option="undo"
                :title="settings.language.undoTitle || ''"
                :disabled="!!cropState"
                small
                @click="onUndoOptionClick"
              >
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <v-btn
                data-option="redo"
                :title="settings.language.redoTitle || ''"
                :disabled="!!cropState"
                small
                @click="onRedoOptionClick"
              >
                <v-icon>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </div>
            <div v-if="settings.chooseStrokeType">
              <div>
                <label v-if="!!settings.language.chooseStrokeType">
                  {{ settings.language.chooseStrokeType }}
                </label>
                <select
                  data-option="stroke-type"
                  :disabled="!!cropState"
                  @change="onStrokeTypeOptionChange"
                >
                  <option value="1" :selected="data.strokeType === 1">
                    {{ settings.language.strokeType[1] || 1 }}
                  </option>
                  <option value="2" :selected="data.strokeType === 2">
                    {{ settings.language.strokeType[2] || 2 }}
                  </option>
                  <option value="3" :selected="data.strokeType === 3">
                    {{ settings.language.strokeType[3] || 3 }}
                  </option>
                  <option value="4" :selected="data.strokeType === 4">
                    {{ settings.language.strokeType[4] || 4 }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="settings.chooseStrokeSize">
              <label v-if="!!settings.language.chooseStrokeSize">
                {{ settings.language.chooseStrokeSize }}
              </label>
              <input
                type="number"
                min="1"
                max="255"
                maxlength="3"
                size="3"
                data-option="stroke-size"
                :disabled="!!cropState"
                :value="data.strokeSize"
                @change="onStrokeSizeOptionChange"
              />
            </div>
            <div v-if="settings.chooseStrokeColor" data-option="stroke-color">
              <label v-if="!!settings.language.chooseStrokeColor">
                {{ settings.language.chooseStrokeColor }}
              </label>
              <v-select
                class="pt-0 ma-0"
                :disabled="!!cropState"
                :items="settings.chooseStrokeColor"
                @change="onStrokeColorOptionChange"
                flat
                append-icon=""
              >
                <template v-slot:item="item" class="pa-0">
                  <v-sheet 
                    height="30px" 
                    width="30px" 
                    outline 
                    rounded 
                    :color="item.item"
                  />
                </template>
                <template slot="prepend-inner">
                  <v-sheet 
                    height="30px" 
                    width="30px" 
                    outline 
                    rounded 
                    :color="data.strokeColor || 'black'"
                  />
                </template>
                <template slot="selection">
                  {{''}}
                </template>
              </v-select>
            </div>
            <div v-if="settings.deleteContent">
              <label v-if="!!settings.language.deleteContent">
                {{ settings.language.deleteContent }}
              </label>
              <v-btn
                data-option="delete-content"
                :title="settings.language.deleteContentTitle || ''"
                :disabled="!!cropState"
                small
                @click="onDeleteOptionClick"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
            <div v-if="settings.uploadImage">
              <label v-if="!!settings.language.uploadImage">
                {{ settings.language.uploadImage }}
              </label>
              <v-btn
                data-option="upload-image"
                :title="settings.language.uploadImageTitle || ''"
                :disabled="!!cropState"
                small
                @click="onImageUploadOptionClick"
              >
                <v-icon>
                  mdi-image
                </v-icon>
              </v-btn>
              <input
                ref="ribbon-option-image-upload"
                data-option="apply-image"
                :title="settings.language.uploadImageTitle || ''"
                type="file"
                :disabled="!!cropState"
                @change="onApplyImageOptionChange"
              />
            </div>
            <div v-if="settings.uploadImage">
              <label v-if="!!settings.language.uploadImage">
                {{ settings.language.uploadImage }}
              </label>
              <v-btn
                data-option="rotate-left"
                :title="settings.language.rotateContentLeftTitle || ''"
                :disabled="!!cropState"
                small
                @click="onRotateLeftOptionClick"
              >
                <v-icon>
                  mdi-undo
                </v-icon>
              </v-btn>
              <v-btn
                data-option="rotate-right"
                :title="settings.language.rotateContentRightTitle || ''"
                :disabled="!!cropState"
                small
                @click="onRotateRigthOptionClick"
              >
                <v-icon>
                  mdi-redo
                </v-icon>
              </v-btn>
            </div>
            <div v-if="settings.scaleContent">
              <label v-if="!!settings.language.scaleContent">
                {{ settings.language.scaleContent }}
              </label>
              <input
                data-option="scale-ratio"
                :placeholder="settings.language.scaleContentPlaceholder || ''"
                type="text"
                :disabled="!!cropState"
                :value="data.scaling"
                @change="onScaleRatioOptionChange"
              />
              <v-btn
                data-option="scale-up"
                :title="settings.language.scaleContentUpTitle || ''"
                :disabled="!!cropState"
                small
                @click="onScaleUpOptionClick"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn
                data-option="scale-down"
                :title="settings.language.scaleContentDownTitle || ''"
                :disabled="!!cropState"
                small
                @click="onScaleDownOptionClick"
              >
                <v-icon>
                  mdi-minus
                </v-icon>
              </v-btn>
            </div>
            <div v-if="settings.cropContent">
              <label v-if="!!settings.language.cropContent">
                {{ settings.language.cropContent }}
              </label>
              <v-btn
                data-option="crop"
                :title="settings.language.cropContentTitle || ''"
                :class="cropState"
                small
                @click="onCropOptionClick"
              >
                <v-icon>
                  mdi-content-cut
                </v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </table>
      <table
        class="drawing-board-inner-container"
        :style="`max-width: ${data.canvasWidth}px`"
      >
        <tr>
          <td class="drawing-board-canvas-container">
            <canvas
              ref="drawing-board-canvas"
              tabindex="-1"
              class="drawing-board"
              :width="settings.canvasWidth"
              :height="settings.canvasHeight"
              :style="canvasStyle"
              @keydown="onKeyDownCanvas"
              @touchstart="onTouchDownCanvas"
              @mousedown="onMouseDownCanvas"
            >
              {{ settings.language.canvasUnsupported }}
            </canvas>
            <div
              v-if="!!settings.cropContent"
              ref="drawing-board-crop-container"
              class="drawing-board-crop"
              :style="cropStyle"
              @touchstart="onTouchDownCrop"
              @mousedown="onMouseDownCrop"
              @dragstart="onCropDragStart"
            >
              <img
                class="drawing-board-crop-image"
                :style="cropImageStyle"
                :src="data.crop.url"
              />
              <div class="drawing-board-crop-inner" />
              <div class="drawing-board-crop-overlay" :style="cropOverlayStyle">
                <div class="drawing-board-crop-overlay-inner">
                  <img
                    class="drawing-board-crop-overlay-image"
                    :style="cropOverlayImageStyle"
                    :src="data.crop.url"
                  />
                  <div class="drawing-board-crop-overlay-border border-top" />
                  <div class="drawing-board-crop-overlay-border border-right" />
                  <div class="drawing-board-crop-overlay-border border-bottom" />
                  <div class="drawing-board-crop-overlay-border border-left" />
                  <button
                    ref="drawing-board-crop-apply"
                    class="drawing-board-crop-overlay-button"
                    :style="cropApplyStyle"
                    action="apply"
                    small
                  >
                    <v-icon action="apply">
                      mdi-check
                    </v-icon>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="drawing-board-ribbon-2" :style="ribbonStyle2">
            <div :class="`drawing-board-${data.ribbonSeverity}`">
              {{ data.ribbonText }}
            </div>
          </td>
        </tr>
      </table>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="onApplyDrawing">
          {{ settings.language.applyDrawing }}
        </v-btn>
        <v-btn @click="onCloseDrawing">
          {{ settings.language.closeDrawing }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/** INFORMATION
 *
 * Unterstützte Optionen:
 * - defaultImage: Daten des Bildes für Canvas.
 *                 Kann entweder ein String oder ein Objekt sein.
 * - canvasWidth: Integer für Breite des Canvas.
 * - canvasHeight: Integer für Höhe des Canvas.
 * - ribbonWidth: Integer für Breite des Ribbons.
 *                Wenn Wert 0 ist, wird die Breite des Canvas verwendet.
 * - language: Objekt mit Sprachstrings.
 *     canvasUnsupported: 'Canvas element not supported.',
 *     imageUploadUnsupportedMimeType: 'Image upload failed, unsupported format.
 *                                      Allowed MIME-Types: ',
 *     strokeType: {
 *       1: 'Line',
 *       2: 'Circle',
 *       3: 'Square',
 *       4: 'Eraser'
 *     },
 *     undo: '',
 *     undoTitle: 'Undo',
 *     redo: '',
 *     redoTitle: 'Redo',
 *     chooseStrokeColor: 'Color:',
 *     chooseStrokeColorTitle: 'Color',
 *     chooseStrokeType: 'Type:',
 *     chooseStrokeSize': 'Size:',
 *     deleteContent: '',
 *     deleteContentTitle: 'Delete content',
 *     uploadImage: '',
 *     uploadImageTitle: 'Upload Image',
 *     cropContent: '',
 *     cropContentTitle: 'Crop',
 *     scaleContent: 'Scale:',
 *     scaleContentUpTitle: 'Upscale',
 *     scaleContentDownTitle: 'Downscale',
 *     scaleContentPlaceholder: 'pixels or percentage',
 *     scaleContentInvalidValue: 'Invalid value.
 *                                Please enter a positive integer or percentage.',
 *     rotateContent: '',
 *     rotateContentLeftTitle: 'Rotate to the left by 90°',
 *     rotateContentRightTitle: 'Rotate to the right by 90°',
 *     missingContext: 'No context for downscaling canvas defined.',
 *     invalidSourceWidth: 'Invalid width of source. Should be bigger than 0 pixel.',
 *     invalidSourceHeight: 'Invalid height of source. Should be bigger than 0 pixel.',
 *     invalidTargetWidth: 'Invalid width of target. Should be bigger than 0 pixel.',
 *     invalidTargetHeight: 'Invalid height of target. Should be bigger than 0 pixel.',
 *     invalidScale: 'Invalid scale. Should be bigger than 0 and lesser than 1',
 *     applyDrawing: 'Apply',
 *     closeDrawing: 'Close'
 *   strokeColor: Farbe des Strichs.
 *   strokeSize: Breite des Strichs.
 *   strokeType: Typ des Strichs:
 *               1 = Linie,
 *               2 = Quadrat,
 *               3 = Kreis,
 *               4 = Radierer
 *   backgroundColor: Definiert die Hintergrundfarbe des Canvas.
 *                    Wenn undefined bleibt der Hintergrund transparent.
 *   restoreMaximum: Integer der angibt, wie viele Versionen
 *                   maximal wiederhergestellt werden können
 *   autoScaleCanvas: Boolean der angibt,
 *                    ob das Canvas auf die Größe des Bildes angepasst wird.
 *   autoScaleRibbons: Boolean der angibt,
 *                     ob das Ribbon auf die Größe des Bildes angepasst wird.
 *   autoScaleImage: Boolean der angibt,
 *                   ob das Bild inder Größe dem Canvas angepasst wird.
 *                   Behält das Seitenverhältnis bei.
 *                   Überschreibt autoScaleCanvas, wenn die Größen
 *                   canvasHeight oder canvasWidth überschreiten.
 *   allowedImageUploadTypes: Erlaubte MIME-Type für Bildformate separiert mit |.
 *   drawingEnabled: Boolean der das Zeichenen auf dem Canvas de-/aktiviert.
 *   ribbonEnabled: Boolean der das Ribbon ein-/ausblendet.
 *   advancedDownScalingEnabled: Boolean der das verbesserte
 *                               Herunterskalieren de-/aktiviert.
 *
 * Benutzeroptionen:
 *   chooseStrokeType: Typ des Strichs kann geändert werden.
 *   chooseStrokeColor: Farbe des Strichs kann geändert werden.
 *   chooseStrokeSize: Breite des Strichs kann geändert werden.
 *   restoreEnabled: Erlaubt Wiederherstellung von Versionen über Buttons
 *                   oder Hotkeys (strg +z, strg + y).
 *   deleteContent: Erlaubt es den Inhalt des Canvas zu löschen.
 *   uploadImage: Erlaubt es eine Bilddatei in das Canvas zu laden.
 *   cropContent: Erlaubt es den Inhalt des Canvas zuzuschneiden.
 *   scaleContent: Erlaubt es den Inhalt des Canvas zu skalieren.
 *   rotateContent: Erlaubt es den Inhalt des Canvas zu drehen.
 *
 * Tastenkombinationen:
 *   Rückgängig machen: strg + z
 *   Wiederherstellen: strg + y
 */
import dialogMixin from '@/mixins/dialogMixin.js'

export default {
  name: 'PluginDrawingboard',
  mixins: [dialogMixin],
  props: [
    'settings',
    'dialog'
  ],
  data() {
    return {
      loaded: 0,
      data: {
        canvas: null,
        canvasWidth: this.settings.canvasWidth || 300,
        canvasHeight: this.settings.canvasHeight || 150,
        ribbonWidth: this.settings.ribbonWidth || null,
        strokeColor: this.settings.strokeColor || 'black',
        strokeSize: this.settings.strokeSize || '1',
        strokeType: parseInt(this.settings.strokeType || '1'),
        lastPoint: null,
        context: undefined,
        history: undefined,
        historyIndex: undefined,
        ribbonText: '',
        ribbonSeverity: 0,
        url: '',
        file: null,
        cache: {},
        scaling: '',
        crop: {
          url: '',
          active: false,
          button: false,
          overlay: false,
          selection: {
            x: 0,
            y: 0,
            x2: 0,
            y2: 0,
            height: 0,
            width: 0
          }
        }
      },
      version: '0.1.2',
      id: null
    }
  },
  computed: {
    ribbonStyle1() {
      const width = this.data.ribbonWidth
      let style = 'margin: 0px auto;'
      if (this.settings.autoScaleRibbons) {
        style += ` width: ${this.data.canvasWidth}px;`
      } else if (typeof width === 'number') {
        style += ` width: ${width}px;`
      } else {
        style += ` max-width: ${this.data.canvasWidth}px;`
      }
      if (
        !this.settings.restoreEnabled &&
        !this.settings.chooseStrokeType &&
        !this.settings.chooseStrokeSize &&
        !this.settings.chooseStrokeColor &&
        !this.settings.deleteContent &&
        !this.settings.uploadImage &&
        !this.settings.uploadImage &&
        !this.settings.scaleContent &&
        !this.settings.cropContent
      ) {
        style += ' display: none;'
      }
      return style
    },
    ribbonStyle2() {
      const width = this.data.ribbonWidth
      let style = 'margin: 0px auto;'
      if (this.settings.autoScaleRibbons) {
        style += ` width: ${this.data.canvasWidth}px;`
      } else if (typeof width === 'number') {
        style += ` width: ${width}px;`
      }
      if (!this.data.ribbonText) {
        style += ' display: none;'
      }
      return style
    },
    canvasStyle() {
      if (this.data.crop.active) {
        return 'display: none;'
      }
      return false
    },
    cropStyle() {
      const height = this.data.canvasHeight
      const width = this.data.canvasWidth
      let style = `height: ${height}px; width: ${width}px;`
      if (this.data.crop.active) {
        style += ' display: inherit;'
      }
      return style
    },
    cropOverlayStyle() {
      const selection = this.data.crop.selection
      let style = `top: ${selection.y2}px; `
      style += `left: ${selection.x2}px; `
      style += `width: ${selection.width}px; `
      style += `height: ${selection.height}px; `
      return style
    },
    cropOverlayImageStyle() {
      const canvas = this.data.canvas || {}
      const selection = this.data.crop.selection
      let style = `top: -${selection.y2}px; `
      style += `left: -${selection.x2}px; `
      style += `width: ${canvas.width || 0}px; `
      style += `height: ${canvas.height || 0}px; `
      return style
    },
    cropImageStyle() {
      let style = this.cropStyle
      if (this.data.crop.overlay) {
        style += ' opacity: 0.6;'
      }
      return style
    },
    cropApplyStyle() {
      if (!this.data.crop.button) {
        return 'opacity: 0;'
      }
      if (this.data.crop.overlay) {
        const selection = this.data.crop.selection
        const button = this.$refs['drawing-board-crop-apply']
        const rect = button.getBoundingClientRect()
        const width = button.offsetWidth || rect.width || 0
        const height = button.offsetHeight || rect.height || 0
        const left = (selection.width - (width || 0)) / 2
        const top = (selection.height - (height || 0)) / 2
        return `left: ${left}px; top: ${top}px;`
      }
      return false
    },
    cropState() {
      if (this.data.crop.active) {
        return 'active'
      }
      return false
    }
  },
  watch: {
    /**
     * Wird ausgeführt wenn die Eigenschaft 'active' vom Property 'dialog'
     * verändert wird.
     *
     * Diese bereitet die Ausführung der Methode 'initialize()' im nächsten
     * 'updated'-Hook vor, wenn der Dialog zum ersten mal dem Benutzer angezeigt
     * wird. Dieses ist wegen der Vue-Refenz 'drawing-board-canvas' nötig, da
     * diese sonst nicht exestiert.
     *
     * @param value - Ob Dialog angezeigt (TRUE) wird oder nicht (FALSE)
     */
    'dialog.active': function(value) {
      if (value && this.loaded === 0) {
        this.loaded = 1
      }
    }
  },
  /**
   * Vue-Hook 'updated', wird nur benötigt da beim ersten Anzeigen des Dialoges
   * die Methode 'initialize()' ausgeführt werden muss. Dieser verwendet aber
   * die Vue-Referenz 'drawing-board-canvas'. Diese exestiert beim ersten
   * Aufruff nach dem ersten Triggern dieses Hooks.
   */
  updated() {
    if (this.dialog.active && this.loaded === 1) {
      this.initialize()
      this.loaded = 2
    }
  },
  methods: {
    initialize() {
      const data = this.data
      const canvas = data.canvas = this.$refs['drawing-board-canvas']
      const settings = this.settings
      const context = data.context = data.canvas.getContext('2d')
      data.history = []
      data.historyIndex = 0
      let width = canvas.width
      let height = canvas.height
      if (settings.backgroundColor) {
        context.beginPath()
        context.rect(0, 0, data.width, data.height)
        context.fillStyle = this.settings.backgroundColor
        context.fill()
      }
      if (settings.defaultImage) {
        const defaultImage = settings.defaultImage
        const type = typeof defaultImage
        switch (type) {
          case 'string':
            const image = new Image()
            image.crossOrigin = 'Anonymous'
            image.onload = () => {
              width = image.width
              height = image.height
              if (settings.autoScaleRibbons) {
                data.ribbonWidth = width
              }
              if (settings.autoScaleCanvas) {
                canvas.width = data.canvasWidth = width
                canvas.height = data.canvasHeight = height
              }
              context.drawImage(image, 0, 0, width, height)
              this.updateHistory()
            }
            image.src = defaultImage
            break
          case 'object':
            context.putImageData(defaultImage, 0, 0, 0, 0, width, height)
            this.updateHistory()
            break
          /**
           * Unbekannten Typ behandeln, als ob kein Bild vorhanden ist
           */
          default:
            this.updateHistory()
            break
        }
      } else {
        this.updateHistory()
      }
    },
    showNotification(message, severity) {
      this.data.ribbonText = message
      this.data.ribbonSeverity = severity
      setTimeout(() => {
        this.data.ribbonText = ''
        this.data.ribbonSeverity = 0
      }, 5000)
    },
    onApplyDrawing() {
      this.$emit('applydrawingboard', {
        file: this.data.file,
        url: this.data.url
      })
    },
    onCloseDrawing() {
      this.$emit('closedrawingboard')
    },
    onRedoOptionClick() {
      this.applyHistoryImage(1)
    },
    onUndoOptionClick() {
      this.applyHistoryImage(-1)
    },
    onStrokeSizeOptionChange(event) {
      const data = this.data
      data.cache = {}
      const value = parseInt(event.target.value)
      if (value > 255) {
        data.strokeSize = 255
      } else if (value <= 0) {
        data.strokeSize = 1
      } else {
        data.strokeSize = value
      }
    },
    onStrokeColorOptionChange(color) {
      this.data.cache = {}
      this.data.strokeColor = color
    },
    onStrokeTypeOptionChange(event) {
      const data = this.data
      data.cache = {}
      const element = event.target
      data.strokeType = parseInt(element.value) || 1
    },
    onImageUploadOptionClick() {
      const element = this.$refs['ribbon-option-image-upload']
      const trigger = new MouseEvent('click')
      element.dispatchEvent(trigger)
    },
    onApplyImageOptionChange(event) {
      if (!this.data.canvas) {
        this.initialize()
      }
      const data = this.data
      data.cache = {}
      const element = event.target
      const file = element.files[0]
      const reader = new FileReader()
      const settings = this.settings
      const canvas = data.canvas
      reader.onloadend = () => {
        let regex = new RegExp('\\+', 'gi')
        let mimeTypes = settings.allowedImageUploadTypes
        if (!mimeTypes) {
          mimeTypes = 'png|jpeg|jpg|gif|bmp|tiff|x-icon|svg+xml|webp|xxx'
        }
        regex = new RegExp('^data:image/(' + mimeTypes.replace(regex, '\\+') + ');', 'gi')
        if (!regex.test(reader.result)) {
          const mimeTypesText = mimeTypes.replace(/\|/gi, ', ')
          const text = (settings.language.imageUploadUnsupportedMimeType || '') + mimeTypesText
          this.showNotification(text, 'warning')
          return
        }
        let width = data.canvasWidth
        let height = data.canvasHeight
        const context = data.context
        let image = new Image()
        let isAdvancedDownScale = false
        image.onload = () => {
          if (settings.autoScaleImage) {
            if (image.height > height || image.width > width) {
              const dHeight = height / image.height
              const dWidth = width / image.width
              let scale = 0
              if (dHeight > dWidth) {
                scale = dWidth
              } else {
                scale = dHeight
              }
              height = Math.ceil(image.height * scale)
              width = Math.ceil(image.width * scale)
              if (settings.advancedDownScalingEnabled) {
                try {
                  const tCanvas = document.createElement('canvas')
                  tCanvas.style.display = 'none'
                  tCanvas.width = image.width
                  tCanvas.height = image.height
                  const tContext = tCanvas.getContext('2d')
                  tContext.drawImage(image, 0, 0, image.width, image.height)
                  image = this.downScaleImage({
                    context: tContext,
                    scale: scale,
                    sourceHeight: image.height,
                    sourceWidth: image.width,
                    targetHeight: height,
                    targetWidth: width
                  })
                  isAdvancedDownScale = true
                } catch (ex) {
                  this.showNotification(settings.language[ex.message] || ex.message, 'warning')
                  return
                }
              }
            } else {
              const dHeight = image.height / height
              const dWidth = image.width / width
              let scale = 0
              if (dHeight > dWidth) {
                scale = dHeight
              } else {
                scale = dWidth
              }
              height = Math.ceil(image.height * scale)
              width = Math.ceil(image.width * scale)
            }
          } else {
            width = image.width || width
            height = image.height || height
          }

          context.clearRect(0, 0, canvas.width, canvas.height)

          if (settings.autoScaleRibbons) {
            data.ribbonWidth = width
          }
          if (settings.autoScaleCanvas || settings.autoScaleImage) {
            canvas.width = data.canvasWidth = width
            canvas.height = data.canvasHeight = height
          }

          if (isAdvancedDownScale) {
            context.putImageData(image, 0, 0)
          } else {
            context.drawImage(image, 0, 0, width, height)
          }
          data.file = file
          this.updateHistory()
        }
        image.src = reader.result
      }
      reader.readAsDataURL(file)
    },
    onRotateLeftOptionClick() {
      this.rotateImage(-90)
    },
    onRotateRigthOptionClick() {
      this.rotateImage(90)
    },
    onScaleRatioOptionChange(event) {
      const value = event.target.value
      const settings = this.settings
      if (!/^([0-9]+%?)|([0-9]+((,|\.)[0-9]+)%)$/.test(value)) {
        this.data.scaling = ''
        this.showNotification(settings.language.scaleContentInvalidValue, 'warning')
      } else {
        this.data.scaling = value
      }
    },
    onScaleUpOptionClick() {
      if (!this.data.canvas) {
        this.initialize()
      }
      const data = this.data
      const canvas = data.canvas
      const settings = this.settings
      let width = canvas.width
      let height = canvas.height
      const context = data.context
      const image = new Image()
      let percentage = false
      let scaling = data.scaling
      if (/%$/.test(scaling)) {
        percentage = true
      }
      scaling = parseFloat(scaling) || 0
      if (!scaling) {
        this.showNotification(settings.language.scaleContentInvalidValue, 'warning')
        return
      }
      const storage = data.cache.scale || {
        imageURL: canvas.toDataURL(),
        ratio: 1
      }
      image.onload = () => {
        if (percentage) {
          scaling = (scaling / 100) + 1
        } else {
          scaling = ((width + scaling) / width)
        }
        scaling = storage.ratio = storage.ratio * scaling
        width = Math.floor(image.width * scaling)
        height = Math.floor(image.height * scaling)

        if (settings.autoScaleRibbons) {
          data.ribbonWidth = width
        }
        context.clearRect(0, 0, canvas.width, canvas.height)
        if (settings.autoScaleRibbons) {
          data.ribbonWidth = width
        }
        canvas.width = data.canvasWidth = width
        canvas.height = data.canvasHeight = height
        context.drawImage(image, 0, 0, width, height)
        this.updateHistory()
        data.cache = {
          scale: storage
        }
      }
      image.src = storage.imageURL
    },
    onScaleDownOptionClick() {
      if (!this.data.canvas) {
        this.initialize()
      }
      const data = this.data
      const canvas = data.canvas
      const settings = this.settings
      let width = canvas.width
      let height = canvas.height
      const context = data.context
      let image = new Image()
      let percentage = false
      let scaling = data.scaling
      let advanced = settings.advancedDownScalingEnabled
      if (/%$/.test(scaling)) {
        percentage = true
      }
      scaling = parseFloat(scaling) || 0
      if (!scaling) {
        this.showNotification(settings.language.scaleContentInvalidValue, 'warning')
        return
      }
      const storage = data.cache.scale || {
        imageURL: canvas.toDataURL(),
        ratio: 1
      }
      image.onload = () => {
        const swidth = width
        const sheight = height
        if (percentage) {
          scaling = (scaling / 100) % 1
        } else if (scaling >= width) {
          scaling = 0
        } else {
          scaling = ((width - scaling) / width)
        }
        storage.ratioTemp = storage.ratio
        scaling = storage.ratio = storage.ratio * scaling
        width = Math.floor(image.width * scaling)
        height = Math.floor(image.height * scaling)

        if (!width || !height) {
          scaling = storage.ratio = storage.ratioTemp
          width = Math.floor(image.width * scaling)
          height = Math.floor(image.height * scaling)
          delete storage.ratioTemp
          const textType = width ? 'invalidTargetWidth' : 'invalidTargetHeight'
          const text = settings.language[textType]
          this.showNotification(text, 'warning')
          return
        }

        if (advanced) {
          try {
            image = this.downScaleImage({
              context: context,
              scale: scaling,
              sourceHeight: sheight,
              sourceWidth: swidth,
              targetHeight: height,
              targetWidth: width
            })
          } catch (ex) {
            if (ex !== 'invalidScale' || !scaling) {
              this.showNotification(settings.language[ex.message] || ex.message, 'warning')
              return
            }
            advanced = false
          }
        }

        context.clearRect(0, 0, canvas.width, canvas.height)

        if (settings.autoScaleRibbons) {
          data.ribbonWidth = width
        }
        canvas.width = data.canvasWidth = width
        canvas.height = data.canvasHeight = height

        if (advanced) {
          context.putImageData(image, 0, 0)
        } else {
          context.drawImage(image, 0, 0, width, height)
        }
        this.updateHistory()
        data.cache = {
          scale: storage
        }
      }
      image.src = storage.imageURL
    },
    onDeleteOptionClick() {
      if (!this.data.canvas) {
        this.initialize()
      }
      const settings = this.settings
      const context = this.data.context
      const canvas = this.data.canvas
      const width = canvas.width
      const height = canvas.height
      this.data.cache = {}
      if (settings.backgroundColor) {
        context.beginPath()
        context.rect(0, 0, width, height)
        context.fillStyle = settings.backgroundColor
        context.fill()
      } else {
        context.clearRect(0, 0, width, height)
      }
      this.updateHistory()
    },
    onCropOptionClick() {
      if (!this.data.canvas) {
        this.initialize()
      }
      const canvas = this.data.canvas
      const crop = this.data.crop
      const selection = crop.selection
      crop.url = canvas.toDataURL()
      crop.active = !crop.active
      crop.button = false
      crop.overlay = false
      selection.x = 0
      selection.y = 0
      selection.x2 = 0
      selection.y2 = 0
      selection.height = 0
      selection.width = 0
    },
    onCropApplyClick(event) {
      if (!this.data.canvas) {
        this.initialize()
      }
      const action = event.target.getAttribute('action')
      if (action === 'apply') {
        const settings = this.settings
        const data = this.data
        const canvas = data.canvas
        const crop = this.data.crop
        const selection = crop.selection
        const x = typeof selection.x2 === 'number' ? selection.x2 : selection.x
        const y = typeof selection.y2 === 'number' ? selection.y2 : selection.y
        const width = selection.width
        const height = selection.height
        const context = this.data.context
        const cropData = context.getImageData(x, y, width, height)
        context.clearRect(0, 0, canvas.width, canvas.height)
        if (settings.autoScaleRibbons) {
          data.ribbonWidth = width
        }
        if (settings.autoScaleCanvas || settings.autoScaleImage) {
          canvas.width = width
          canvas.height = height
        }
        context.putImageData(cropData, 0, 0)
        crop.active = false
        this.data.cache = {}
        this.updateHistory()
      }
    },
    onCropDragStart(event) {
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()
      return false
    },
    onTouchDownCrop(event) {
      if (!this.settings.cropContent) {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        return false
      }
      event.preventDefault()
      const rect = event.target.getBoundingClientRect()
      const offset = {
        top: rect.top,
        left: rect.left
      }
      const touches = event.touches[0]
      event.offsetX = (event.clientX = touches.clientX) - offset.left
      event.offsetY = (event.clientY = touches.clientY) - offset.top
      this.onMouseDownCrop(event)
      return false
    },
    onTouchMoveCrop(event) {
      event.preventDefault()
      const rect = event.target.getBoundingClientRect()
      const offset = {
        top: rect.top,
        left: rect.left
      }
      const touches = event.touches[0]
      event.offsetX = (event.clientX = touches.clientX) - offset.left
      event.offsetY = (event.clientY = touches.clientY) - offset.top
      this.onMouseMoveCrop(event)
    },
    onTouchUpCrop(event) {
      event.preventDefault()
      this.onMouseUpCrop(event)
    },
    onMouseDownCrop(event) {
      if (!this.settings.cropContent) {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        return false
      }
      let target = event.target
      const crop = this.data.crop
      const selection = crop.selection
      crop.button = false
      target = this.$refs['drawing-board-crop-container']
      if (crop.overlay) {
        this.onCropApplyClick(event)
        selection.x = 0
        selection.y = 0
        selection.x2 = 0
        selection.y2 = 0
        selection.height = 0
        selection.width = 0
        crop.overlay = false
        switch (event.type) {
          case 'mousedown':
            target.removeEventListener('mousemove', this.onMouseMoveCrop)
            document.removeEventListener('mouseup', this.onMouseUpCrop)
            break
          case 'touchstart':
            target.removeEventListener('touchmove', this.onTouchMoveCrop)
            document.removeEventListener('touchend', this.onTouchUpCrop)
            break
        }
      } else {
        selection.x = event.offsetX < 0 ? 0 : event.offsetX
        selection.y = event.offsetY < 0 ? 0 : event.offsetY
        crop.overlay = true
        switch (event.type) {
          case 'mousedown':
            target.addEventListener('mousemove', this.onMouseMoveCrop)
            document.addEventListener('mouseup', this.onMouseUpCrop)
            break
          case 'touchstart':
            target.addEventListener('touchmove', this.onTouchMoveCrop)
            document.addEventListener('touchend', this.onTouchUpCrop)
            break
        }
      }
    },
    onMouseMoveCrop(event) {
      if (!this.data.canvas) {
        this.initialize()
      }
      event.preventDefault()
      const rect = event.target.getBoundingClientRect()
      let offsetX = event.clientX - rect.left
      let offsetY = event.clientY - rect.top
      const selection = this.data.crop.selection
      if (selection && offsetX > 0 && offsetY > 0) {
        /**
         * Notiz:
         * offsetX und offsetY müssen größer als 0 sein,
         * ansonsten ist es eine Selektion.
         * Verursacht 'springen' der Selektion,
         * wenn diese Fälle nicht ignoriert werden.
         */
        const canvas = this.data.canvas
        let x = selection.x
        let y = selection.y
        let width = 0
        let height = 0
        const cWidth = canvas.width
        const cHeight = canvas.height

        if (offsetX > cWidth) {
          offsetX = cWidth
        }
        if (offsetX < x) {
          width = selection.width = -offsetX + x
          x = selection.x2 = offsetX
        } else {
          selection.x2 = x
          width = selection.width = offsetX - x
        }
        if (width > canvas.width) {
          width = selection.width = canvas.width
        }
        if (offsetY > cHeight) {
          offsetY = cHeight
        }
        if (offsetY < y) {
          height = selection.height = -offsetY + y
          y = selection.y2 = offsetY
        } else {
          selection.y2 = y
          height = selection.height = offsetY - y
        }
        if (height > canvas.height) {
          height = selection.height = canvas.height
        }
      }
    },
    onMouseUpCrop(event) {
      if (this.data.crop.overlay) {
        this.data.crop.button = true
        const target = this.$refs['drawing-board-crop-container']
        switch (event.type) {
          case 'mouseup':
            target.removeEventListener('mousemove', this.onMouseMoveCrop)
            document.removeEventListener('mouseup', this.onMouseUpCrop)
            break
          case 'touchend':
            target.removeEventListener('touchmove', this.onTouchMoveCrop)
            document.removeEventListener('touchend', this.onTouchUpCrop)
            break
        }
      }
    },
    onTouchDownCanvas(event) {
      if (!this.data.canvas) {
        this.initialize()
      }
      if (!this.settings.drawingEnabled) {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        return false
      }
      const canvas = this.data.canvas
      canvas.addEventListener('touchmove', this.onTouchMoveCanvas)
      document.addEventListener('touchend', this.onTouchUpCanvas)
      return false
    },
    onTouchMoveCanvas(event) {
      if (!this.data.canvas) {
        this.initialize()
      }
      event.preventDefault()
      const data = this.data
      const rect = data.canvas.getBoundingClientRect()
      const offset = {
        top: rect.top,
        left: rect.left
      }
      const touches = event.touches[0]
      const offsetX = (event.clientX = touches.clientX) - offset.left
      const offsetY = (event.clientY = touches.clientY) - offset.top
      if (data.lastPoint !== null) {
        const functionName = `strokeType${(data.strokeType || 1)}`
        const stroke = this[functionName] || (() => {})
        stroke.call(this, event, offsetX, offsetY)
      } else {
        data.lastPoint = {
          x: touches.clientX - offset.left,
          y: touches.clientY - offset.top
        }
      }
    },
    onTouchUpCanvas(event) {
      if (!this.data.canvas) {
        this.initialize()
      }
      event.preventDefault()
      document.removeEventListener('touchend', this.onTouchUpCanvas)
      const canvas = this.data.canvas
      canvas.removeEventListener('touchmove', this.onTouchMoveCanvas)
      this.updateHistory()
      this.data.lastPoint = null
      this.data.cache = {}
    },
    onMouseDownCanvas(event) { // start mouse
      if (!this.data.canvas) {
        this.initialize()
      }
      if (!this.settings.drawingEnabled) {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        return false
      }
      const canvas = this.data.canvas
      canvas.addEventListener('mousemove', this.onMouseMoveCanvas)
      document.addEventListener('mouseup', this.onMouseUpCanvas)
    },
    onMouseMoveCanvas(event) { // draw mouse
      if (!this.data.canvas) {
        this.initialize()
      }
      event.preventDefault()
      const data = this.data
      const rect = data.canvas.getBoundingClientRect()
      const offset = {
        top: rect.top,
        left: rect.left
      }
      const offsetX = event.clientX - offset.left
      const offsetY = event.clientY - offset.top
      if (data.lastPoint !== null) {
        const functionName = `strokeType${(data.strokeType || 1)}`
        const stroke = this[functionName] || (() => {})
        stroke.call(this, event, offsetX, offsetY)
      } else {
        data.lastPoint = {
          x: event.clientX - offset.left,
          y: event.clientY - offset.top
        }
      }
    },
    onMouseUpCanvas() { // end mouse
      if (!this.data.canvas) {
        this.initialize()
      }
      document.removeEventListener('mouseup', this.onMouseUpCanvas)
      const canvas = this.data.canvas
      canvas.removeEventListener('mousemove', this.onMouseMoveCanvas)
      this.updateHistory()
      this.data.lastPoint = null
      this.data.cache = {}
    },
    onKeyDownCanvas(event) {
      if (!this.settings.restoreEnabled) {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        return false
      }
      if (event.ctrlKey) {
        return (this[`keyCodeCanvasAction${[event.keyCode]}`] || (() => {}))(event)
      }
    },
    keyCodeCanvasAction89(event) { // y
      this.applyHistoryImage(1)
      event.stopImmediatePropagation()
      event.stopPropagation()
      return false
    },
    keyCodeCanvasAction90(event) { // z
      this.applyHistoryImage(-1)
      event.stopImmediatePropagation()
      event.stopPropagation()
      return false
    },
    strokeType1(event, x, y) { // Linie
      const data = this.data
      const context = data.context
      const lastPoint = data.lastPoint
      context.beginPath()
      context.moveTo(lastPoint.x, lastPoint.y)
      context.lineTo(x, y)
      context.strokeStyle = data.strokeColor || 'black'
      context.lineWidth = data.strokeSize || 3
      context.stroke()
      lastPoint.x = x
      lastPoint.y = y
    },
    strokeType2(event, x, y) { // Kreis
      const data = this.data
      const lastPoint = data.lastPoint
      const _x = lastPoint.x
      const _y = lastPoint.y
      this.drawLineCircle(x, _x, y, _y, data.strokeColor || 'black')
      lastPoint.x = x
      lastPoint.y = y
    },
    strokeType3(event, x, y) { // Quadrat
      const data = this.data
      const lastPoint = data.lastPoint
      const _x = lastPoint.x
      const _y = lastPoint.y
      this.drawLineSquare(x, _x, y, _y, data.strokeColor || 'black')
      lastPoint.x = x
      lastPoint.y = y
    },
    strokeType4(event, x, y) { // Radierer, Kreis
      const data = this.data
      const lastPoint = data.lastPoint
      const _x = lastPoint.x
      const _y = lastPoint.y
      const settings = this.settings
      this.drawLineCircle(x, _x, y, _y, settings.backgroundColor)
      lastPoint.x = x
      lastPoint.y = y
    },
    drawLineCircle(x, _x, y, _y, color) {
      const data = this.data
      const context = data.context
      const radius = (data.strokeSize || 3) / 2
      const circle = 2 * Math.PI
      let diffX = x - _x
      let diffY = y - _y

      if (diffX < 0 || (!diffX && diffY < 0)) {
        let temp = x
        x = _x
        _x = temp
        temp = y
        y = _y
        _y = temp
        diffX = x - _x
        diffY = y - _y
      }

      while (Math.abs(diffX) > 1 || Math.abs(diffY) > 1) {
        diffX = diffX / 10
        diffY = diffY / 10
      }

      context.lineWidth = 1

      if (!color) {
        context.globalCompositeOperation = 'destination-out'
      }

      if (diffX) {
        do {
          context.beginPath()
          context.arc(_x, _y, radius, 0, circle)
          context.fillStyle = color
          context.fill()
          context.lineWidth = 0
          context.strokeStyle = color
          context.stroke()
          _x += diffX
          _y += diffY
        } while (x >= _x)
      } else if (diffY) {
        do {
          context.beginPath()
          context.arc(_x, _y, radius, 0, circle)
          context.fillStyle = color
          context.fill()
          context.lineWidth = 0
          context.strokeStyle = color
          context.stroke()
          _x += diffX
          _y += diffY
        } while (y >= _y)
      }

      context.globalCompositeOperation = 'source-over'
    },
    drawLineSquare(x, _x, y, _y, color) {
      const data = this.data
      const context = data.context
      const size = data.strokeSize || 3
      const offset = (data.strokeSize || 3) / 2
      let diffX = x - _x
      let diffY = y - _y

      if (diffX < 0 || (!diffX && diffY < 0)) {
        let temp = x
        x = _x
        _x = temp
        temp = y
        y = _y
        _y = temp
        diffX = x - _x
        diffY = y - _y
      }

      while (Math.abs(diffX) > 1 || Math.abs(diffY) > 1) {
        diffX = diffX / 10
        diffY = diffY / 10
      }

      context.lineWidth = 1

      if (!color) {
        context.globalCompositeOperation = 'destination-out'
      }

      if (diffX) {
        do {
          context.beginPath()
          context.rect(_x - offset, _y - offset, size, size)
          context.fillStyle = color
          context.fill()
          context.lineWidth = 0
          context.strokeStyle = color
          context.stroke()
          _x += diffX
          _y += diffY
        } while (x >= _x)
      } else if (diffY) {
        do {
          context.beginPath()
          context.rect(_x - offset, _y - offset, size, size)
          context.fillStyle = color
          context.fill()
          context.lineWidth = 0
          context.strokeStyle = color
          context.stroke()
          _x += diffX
          _y += diffY
        } while (y >= _y)
      }

      context.globalCompositeOperation = 'source-over'
    },
    applyHistoryImage(offset) {
      if (!this.data.canvas) {
        this.initialize()
      }
      const data = this.data
      data.cache = {}
      if (!offset) { return }
      const history = data.history || []
      const historyIndex = data.historyIndex
      const settings = this.settings
      if (
        (offset > 0 && historyIndex < (history.length - 1)) ||
        (offset < 0 && historyIndex)
      ) {
        const historyIndexNew = this.data.historyIndex = historyIndex + offset
        const imageURL = history[historyIndexNew]
        const image = new Image()
        const context = this.data.context
        const canvas = this.data.canvas
        let width = canvas.width
        let height = canvas.height
        image.onload = () => {
          width = image.width
          height = image.height
          context.clearRect(0, 0, canvas.width, canvas.height)
          if (settings.autoScaleRibbons) {
            data.ribbonWidth = width
          }
          if (settings.autoScaleCanvas) {
            canvas.width = data.canvasWidth = width
            canvas.height = data.canvasHeight = height
          }
          context.drawImage(image, 0, 0, width, height)
          if (typeof settings.onChange === 'function') {
            settings.onChange(canvas, imageURL)
          }
        }
        image.src = imageURL
      }
    },
    updateHistory() {
      if (!this.data.canvas) {
        this.initialize()
      }
      const data = this.data
      const history = data.history || []
      const historyIndex = data.historyIndex
      const canvas = data.canvas
      const settings = this.settings
      const imageURL = canvas.toDataURL()
      if (history[historyIndex] !== imageURL) {
        if (historyIndex < history.length - 1) {
          data.history = history.splice(0, historyIndex + 1)
        }
        history.push(imageURL)
        if (
          settings.restoreMaximum &&
          settings.restoreMaximum < history.length
        ) {
          history.splice(0, 1)
        }
        data.historyIndex = history.length - 1
        data.url = imageURL
      }
    },
    downScaleImage(data) {
      let context
      let sourceWidth
      let sourceHeight
      let targetWidth
      let targetHeight
      if (!(context = data.context)) {
        throw new Error('missingContext')
      }
      if (!(sourceWidth = data.sourceWidth)) {
        throw new Error('invalidSourceWidth')
      }
      if (!(sourceHeight = data.sourceHeight)) {
        throw new Error('invalidSourceHeight')
      }
      if (!Math.round(targetWidth = data.targetWidth)) {
        throw new Error('invalidTargetWidth')
      }
      if (!Math.round(targetHeight = data.targetHeight)) {
        throw new Error('invalidTargetHeight')
      }
      if (!data.scale || data.scale >= 1) {
        throw new Error('invalidScale')
      }

      const scale = sourceWidth / targetWidth
      const scaleh = Math.ceil(scale / 2)
      const iData = context.getImageData(0, 0, sourceWidth, sourceHeight).data
      const image = context.createImageData(targetWidth, targetHeight)
      const iData2 = image.data

      for (let j = 0; j < targetHeight; j++) {
        for (let i = 0; i < targetWidth; i++) {
          const x2 = (i + j * targetWidth) * 4
          let weight = 0
          let weights = 0
          let weightsAlpha = 0
          let gxr = 0
          let gxg = 0
          let gxb = 0
          let gxa = 0
          const centerY = (j + 0.5) * scale
          const yyStart = Math.floor(j * scale)
          const yyStop = Math.ceil((j + 1) * scale)

          for (let yy = yyStart; yy < yyStop; yy++) {
            const dy = Math.abs(centerY - (yy + 0.5)) / scaleh
            const centerX = (i + 0.5) * scale
            const w0 = dy * dy // pre-calc part of w
            const xxStart = Math.floor(i * scale)
            const xxStop = Math.ceil((i + 1) * scale)

            for (let xx = xxStart; xx < xxStop; xx++) {
              const dx = Math.abs(centerX - (xx + 0.5)) / scaleh
              const w = Math.sqrt(w0 + dx * dx)
              if (w >= 1) {
                // pixel too far
                continue
              }
              // hermite filter
              weight = 2 * w * w * w - 3 * w * w + 1
              const posX = 4 * (xx + yy * sourceWidth)
              // alpha
              gxa += weight * iData[posX + 3]
              weightsAlpha += weight
              // colors
              if (iData[posX + 3] < 255) {
                weight = weight * iData[posX + 3] / 250
              }
              gxr += weight * iData[posX]
              gxg += weight * iData[posX + 1]
              gxb += weight * iData[posX + 2]
              weights += weight
            }
          }
          iData2[x2] = gxr / weights
          iData2[x2 + 1] = gxg / weights
          iData2[x2 + 2] = gxb / weights
          iData2[x2 + 3] = gxa / weightsAlpha
        }
      }

      return image
    },
    rotateImage(value) {
      if (!this.data.canvas) {
        this.initialize()
      }
      const data = this.data
      const context = data.context
      const canvas = data.canvas
      const settings = this.settings
      const storage = data.cache.rotate || {
        imageURL: canvas.toDataURL(),
        rotation: 0
      }
      const imageURL = storage.imageURL
      const image = new Image()
      let width = canvas.width
      let height = canvas.height
      value = storage.rotation = (storage.rotation + (value || 0)) % 360
      image.onload = () => {
        const widthImage = image.width
        const heightImage = image.height
        const radian = value * Math.PI / 180

        // Inhalt des Canvas entfernen
        if (settings.backgroundColor) {
          context.beginPath()
          context.rect(0, 0, widthImage, heightImage)
          context.fillStyle = settings.backgroundColor
          context.fill()
        } else {
          context.clearRect(0, 0, widthImage, heightImage)
        }

        // Neue Canvas- und Ribbongröße setzen
        if (settings.autoScaleCanvas) {
          const temp = height
          height = width
          width = temp
        }
        if (settings.autoScaleRibbons) {
          data.ribbonWidth = width
        }
        if (settings.autoScaleCanvas) {
          canvas.width = data.canvasWidth = width
          canvas.height = data.canvasHeight = height
        }

        context.translate(width / 2, height / 2)
        context.rotate(radian)
        context.drawImage(image, -widthImage / 2, -heightImage / 2, widthImage, heightImage)
        context.rotate(-radian)
        context.translate(-width / 2, -height / 2)
        this.updateHistory()
        data.cache = {
          rotate: storage
        }
      }
      image.src = imageURL
    }
  }
}
</script>

<style>
  .drawing-board-dialog {
    width: initial;
  }

  .drawing-board-container {
    padding: 5px;
  }

  .drawing-board-outer-container {
    overflow: overlay;
    padding: 20px;
  }

  .drawing-board-inner-container {
    margin: 0px auto;
  }

  .drawing-board-canvas-container {
    background-color: #FFFFFF;
  }

  .drawing-board,
  .drawing-board-crop {
    outline: 1px solid #cccccc;
    display: block;
    margin-bottom: 2px;
  }

  .drawing-board-ribbon-container {
    border-collapse: collapse;
    border-spacing: 0px;
    border-color: transparent;
  }

  .drawing-board-ribbon,
  .drawing-board-ribbon-2 {
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin: 0px auto;
    margin-bottom: 5px;
    display: table;
    min-width: 175px;
  }

  .drawing-board-ribbon button,
  .drawing-board-ribbon-2 button {
    width: 30px;
    height: 30px;
    padding: 0px;
    margin: 0px;
    min-width: 30px;
  }

  .drawing-board-ribbon button.fa,
  .drawing-board-ribbon-2 button.fa,
  .drawing-board-crop button.fa {
    font-family: FontAwesome;
  }

  .drawing-board-ribbon button[data-option="undo"],
  .drawing-board-ribbon button[data-option="rotate-left"],
  .drawing-board-ribbon input[data-option="scale-ratio"],
  .drawing-board-ribbon button[data-option="scale-up"] {
    margin-right: 2px;
  }

  .drawing-board-ribbon button[data-option="scale-down"],
  .drawing-board-ribbon button[data-option="scale-up"] {
    vertical-align: top
  }

  .drawing-board-ribbon input[data-option="scale-ratio"] {
    border: 1px solid #ccc;
    padding-left: 2px;
  }

  .drawing-board-ribbon input[data-option="stroke-size"] {
    max-width: 50px;
    border: 1px solid #ccc;
    padding-left: 2px;
  }

  .drawing-board-ribbon select[data-option="stroke-type"] {
    min-width: 42px;
    border: 1px solid #ccc;
    padding-left: 2px;
  }

  .drawing-board-ribbon div[data-option="stroke-color"] {
    max-width: min-content;
    display: flex;
    margin-right: 12px;
  }

  .drawing-board-ribbon div[data-option="stroke-color"] label {
    height: 19px; 
    margin-top: 4px;
  }

  .drawing-board-ribbon input[data-option="apply-image"] {
    visibility: none;
    position: absolute;
    width: 1px;
    height: 1px;
    top: -100px;
    left: 0px;
  }

  .drawing-board-ribbon-2 input[data-option="rotate-by"] {
    min-width: 200px;
  }

  .drawing-board-ribbon button[data-option="crop"].active i {
    color: #080;
  }

  .drawing-board-ribbon input,
  .drawing-board-ribbon-2 input,
  .drawing-board-ribbon select,
  .drawing-board-ribbon-2 select {
    box-sizing: border-box;
    height: 30px;
  }

  .drawing-board-ribbon label,
  .drawing-board-ribbon-2 label {
    margin-right: 5px;
    font-weight: bold;
  }

  .drawing-board-ribbon > div,
  .drawing-board-ribbon-2 > div  {
    float: left;
    margin: 5px;
  }

  .drawing-board-warning {
    padding: 4px;
    font-weight: bold;
    color: #FF0000;
    overflow-wrap: break-word;
  }

  .drawing-board-crop {
    position: relative;
    display: none;
    cursor: crosshair;
    background: rgba(0,0,0,0.2);
    overflow: hidden;
  }

  .drawing-board-crop .drawing-board-crop-image {
    top: 0px;
    left: 0px;
    opacity: 1;
    position: absolute;
  }

  .drawing-board-crop .drawing-board-crop-inner {
    top: 0px;
    left: 0px;
    opacity: 1;
    position: absolute;
    -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

  .drawing-board-crop .drawing-board-crop-overlay {
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 0px;
  }

  .drawing-board-crop .drawing-board-crop-overlay-inner {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .drawing-board-crop .drawing-board-crop-overlay-image {
    margin: 0px;
    padding: 0px;
    position: absolute;
    visibility: visible;
  }

  .drawing-board-crop .drawing-board-crop-overlay-button {
    width: 30px;
    height: 30px;
    position: absolute;
    padding: 0px;
    opacity: 0.9;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 5px 5px 5px #999;
    background-color: #F5F5F5;
    min-width: 30px;
  }

  .drawing-board-crop .drawing-board-crop-overlay-button:hover {
    background-color: rgba(0, 0, 0, .12);
  }

  .drawing-board-crop .drawing-board-crop-overlay-button i {
    font-size: 28px;
    color: #080;
  }

  .drawing-board-crop .drawing-board-crop-overlay-border {
    position: absolute;
    border: 1px dashed #000;
  }

  .drawing-board-crop .border-top {
    height: 0px;
    width: 100%;
    left: 0px;
    top: -1px;
  }

  .drawing-board-crop .border-right {
    height: 100%;
    width: 0px;
    left: calc(100% - 1px);
    top: -2px;
  }

  .drawing-board-crop .border-bottom {
    height: 0px;
    width: 100%;
    left: 0px;
    top: calc(100% - 1px);
  }

  .drawing-board-crop .border-left {
    height: 100%;
    width: 0px;
    left: -1px;
    top: -1px;
  }

  .v-menu__content .v-list{
    padding: 0px !important;
  }

  .v-application .white {
    border-color: rgb(65, 65, 65) !important;
    border: 1px solid;
  }

  .v-text-field__details {
    display: none;
  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    border: none; 
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    border: none;
  }

  .v-input__prepend-inner {
    margin: 0px !important;
  }

</style>
