<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-img
      :class="classes"
      :disabled="disabled"
      :style="style"
      :min-width="minWidth"
      :width="width"
      :min-height="minHeight"
      :height="height"
      :src="drawing"
      :title="title"
      @click="onClick"
      @mousedown="onMouseDown"
      @contextmenu="onContextMenu"
      @dragstart="onDragStart"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-icon>
            mdi-image-edit-outline
          </v-icon>
        </v-row>
      </template>
    </v-img>
    <template v-if="showTimestamp">
      <div class="drawing-timestamp" v-dompurify-html="timestamp" />
    </template>
    <plugin-drawingboard
      v-if="!disabled"
      v-model="dialog.active"
      :settings="settings"
      :dialog="dialog"
      @closedrawingboard="onDrawingBoardClose"
      @applydrawingboard="onDrawingBoardApply"
    />
  </td>
</template>

<script>
import PluginDrawingboard from '@/components/ChecklistBuilder/Plugin/DrawingBoard.vue'

export default {
  name: 'ChecklistCellTypeDrawingBoard',
  components: {
    PluginDrawingboard
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      drawing: '',
      dialog: {
        active: false
      }
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    classes() {
      let classes = this.state.classes || ''
      classes += `drawing frame${this.frame} `
      if (this.protected) {
        classes += 'protected '
      }
      if (this.drawing) {
        classes += 'none '
      }
      return classes
    },
    disabled() {
      return !!this.state.disabled
    },
    protected() {
      const settings = this.cell.settings
      return settings.drawing_protected
    },
    minWidth() {
      return 30
    },
    width() {
      const settings = this.cell.settings
      return parseInt(settings.drawing_width) || 'auto'
    },
    minHeight() {
      return 30
    },
    height() {
      const settings = this.cell.settings
      return parseInt(settings.drawing_height) || 'auto'
    },
    frame() {
      const settings = this.cell.settings
      return parseInt(settings.drawing_frame) || 0
    },
    title() {
      return this.lang('ChecklistBuilder.titles.drawing_board_display')
    },
    showTimestamp() {
      return !!this.cell.settings.drawing_timestamp
    },
    timestamp() {
      const builder = this.data.ChecklistBuilder
      let timestamp = this.value.timestamp
      const settings = this.cell.settings
      if (timestamp && settings.drawing_timestamp) {
        if (settings.drawing_timestamp_date && settings.drawing_timestamp_time) {
          timestamp = builder.getDateTime(timestamp)
        } else if (settings.drawing_timestamp_date) {
          timestamp = builder.getDate(timestamp)
        } else if (settings.drawing_timestamp_time) {
          timestamp = builder.getTime(timestamp)
        }
      }
      return timestamp
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = builder.getCellValue(index, this.cell, this.clone, {
        drawing: '',
        timestamp: '',
        'document_id': 0
      })
      return value
    },
    settings() {
      const settings = this.cell.settings || {}
      // Ändern, wenn ein Colorpicker plugin existiert, true = Colorpicker
      let colors = settings.drawing_board_ribbon_stroke_color
      if (colors && !Array.isArray(colors)) {
        colors = ['white', 'black', 'green', 'red', 'blue', 'yellow']
      }
      return {
        canvasWidth: settings.drawing_board_canvas_width,
        canvasHeight: settings.drawing_board_canvas_height,
        strokeColor: settings.drawing_board_stroke_color,
        strokeSize: settings.drawing_board_stroke_size,
        strokeType: settings.drawing_board_stroke_type,
        backgroundColor: settings.drawing_board_background_color,
        autoScaleCanvas: settings.drawing_board_auto_scale_canvas,
        autoScaleRibbons: settings.drawing_board_auto_scale_ribbons,
        autoScaleImage: settings.drawing_board_ribbon_auto_scale_image,
        allowedImageUploadTypes: 'png|jpg|jpeg|gif|svg+xml',
        drawingEnabled: !settings.drawing_board_drawing_disabled,
        advancedDownScalingEnabled: settings.drawing_board_advanced_down_scale,
        restoreEnabled: settings.drawing_board_ribbon_restore,
        restoreMaximum: 10,
        ribbonEnabled: settings.drawing_board_ribbon,
        chooseStrokeType: settings.drawing_board_ribbon_stroke_type,
        chooseStrokeSize: settings.drawing_board_ribbon_stroke_size,
        chooseStrokeColor: colors,
        deleteContent: settings.drawing_board_ribbon_delete,
        uploadImage: settings.drawing_board_ribbon_upload_image,
        cropContent: settings.drawing_board_ribbon_crop,
        scaleContent: settings.drawing_board_ribbon_scale,
        rotateContent: settings.drawing_board_ribbon_rotate,
        language: {
          canvasUnsupported:
            this.lang('ChecklistBuilder.message.content.canvas_unsupported'),
          imageUploadUnsupportedMimeType:
            this.lang('ChecklistBuilder.message.content.image_upload_unsupported_mime_type'),
          strokeType: {
            1: this.lang('ChecklistBuilder.templates.dropdown.drawing_board_stroke_type_1'),
            2: this.lang('ChecklistBuilder.templates.dropdown.drawing_board_stroke_type_2'),
            3: this.lang('ChecklistBuilder.templates.dropdown.drawing_board_stroke_type_3'),
            4: this.lang('ChecklistBuilder.templates.dropdown.drawing_board_stroke_type_4')
          },
          undoTitle:
            this.lang('ChecklistBuilder.titles.undo'),
          redoTitle:
            this.lang('ChecklistBuilder.titles.redo'),
          chooseStrokeColor:
            this.lang('ChecklistBuilder.templates.settings.color'),
          chooseStrokeColorTitle:
            this.lang('ChecklistBuilder.titles.color'),
          chooseStrokeType:
            this.lang('ChecklistBuilder.templates.settings.type'),
          chooseStrokeSize:
            this.lang('ChecklistBuilder.templates.settings.size'),
          deleteContentTitle:
            this.lang('ChecklistBuilder.titles.delete_content'),
          uploadImageTitle:
            this.lang('ChecklistBuilder.titles.upload_image'),
          cropContentTitle:
            this.lang('ChecklistBuilder.titles.crop'),
          scaleContent:
            this.lang('ChecklistBuilder.templates.settings.scale'),
          scaleContentUpTitle:
            this.lang('ChecklistBuilder.titles.upscale'),
          scaleContentDownTitle:
            this.lang('ChecklistBuilder.titles.downscale'),
          scaleContentPlaceholder:
            this.lang('ChecklistBuilder.placeholders.scaling'),
          scaleContentInvalidValue:
            this.lang('ChecklistBuilder.message.content.scaling_invalid_value'),
          rotateContentLeftTitle:
            this.lang('ChecklistBuilder.titles.rotate_left_90'),
          rotateContentRightTitle:
            this.lang('ChecklistBuilder.titles.rotate_right_90'),
          missingContext:
            this.lang('ChecklistBuilder.message.content.scaling_canvas_no_context'),
          invalidSourceWidth:
            this.lang('ChecklistBuilder.message.content.scaling_invalid_source_width'),
          invalidSourceHeight:
            this.lang('ChecklistBuilder.message.content.scaling_invalid_source_height'),
          invalidTargetWidth:
            this.lang('ChecklistBuilder.message.content.scaling_invalid_target_width'),
          invalidTargetHeight:
            this.lang('ChecklistBuilder.message.content.scaling_invalid_target_height'),
          invalidScale:
            this.lang('ChecklistBuilder.message.content.scaling_invalid_scale'),
          applyDrawing:
            this.lang('ChecklistBuilder.misc.buttons.apply_drawing'),
          closeDrawing:
            this.lang('ChecklistBuilder.misc.buttons.close')
        },
        defaultImage: this.getDrawing()
      }
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    getDrawing() {
      this.drawing = this.value.drawing
      return this.drawing
    },
    onDrawingBoardApply(event) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      // TODO event.file Funktionalität muss noch implementiert werden.
      const timestamp = +new Date()
      const value = {
        drawing: event.url,
        timestamp: timestamp,
        'document_id': 0
      }
      const previousValue = this.value
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
      this.dialog.active = false
    },
    onDrawingBoardClose() {
      this.dialog.active = false
    },
    onClick() {
      if (this.disabled) {
        return
      }
      this.dialog.active = true
    },
    onMouseDown(event) {
      if (this.protected) {
        if (event.button === 2) {
          event.preventDefault()
          event.stopImmediatePropagation()
          event.stopPropagation()
          return false
        }
      }
    },
    onContextMenu(event) {
      if (this.protected) {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        return false
      }
    },
    onDragStart(event) {
      if (this.protected) {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        return false
      }
    }
  }
}
</script>

<style>
.checklist-viewer .element-content > .drawing {
  display: inline-block;
}

.checklist-viewer .element-content > .drawing:not([disabled]) {
  cursor: pointer;
}

.checklist-viewer .element-content .drawing[disabled] {
  pointer-events: none;
}

.theme--dark .checklist-viewer .element-content > .drawing.none {
  background: #FFF;
}

.theme--dark .checklist-viewer .element-content > .drawing.frame1 {
  border: 1px solid #dddddd;
}

.theme--dark .checklist-viewer .element-content > .drawing.frame2 {
  border-bottom: 1px solid #dddddd;
}

.theme--light .checklist-viewer .element-content > .drawing.frame1 {
  border: 1px solid #000000;
}

.theme--light .checklist-viewer .element-content > .drawing.frame2 {
  border-bottom: 1px solid #000000;
}

.checklist-viewer .element-content > div.drawing-timestamp {
  padding-top: 10px;
}

.checklist-viewer .element-content .drawing.disabled-content-custom,
.checklist-viewer .element-content .drawing.disabled-content,
.checklist-viewer .element-content .drawing.disabled-cell,
.checklist-viewer .element-content .drawing.disabled-checklist,
.checklist-viewer .element-content .drawing.disabled-collection {
  opacity: 1;
}
</style>
